@font-face {
  font-family: 'Gacor';
  font-style: normal;
  font-weight: normal;
  src: local('Gacor'), url('fonts/Gacor.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('fonts/Roboto-Regular.woff') format('woff');
}
/** Default styles **/
body, span {
  font-family: 'Roboto-Regular';
}
button.MuiButton-root {
  border-radius: 20px;
}
li.truncated-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-search-box {
  width: 100%;
}
/** /Default styles **/

.app-bar {
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  color: #A133B1;
}

/** TitleBar **/
.title-app {
  letter-spacing: 0.2em;
  margin: 0;
}
.title-app.subtitle {
  letter-spacing: 0.1em;
  margin-top: 22px;
}
.title-container {
  display: flex;
}
/** /TitleBar **/

.logo-alacuenta {
  display: inline-block;
  float: left;
  padding: 0 10px;
}

/** Header **/
.menuButton {
  margin-right: 10px;
}
.title {
  flex-grow: 1;
}

/** Login **/
.login-form {
  display: flex;
  flex-flow: column;
  margin: 20px auto;
  width: 280px;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}
.login-form .logo-alacuenta {
  height: 100px;
  width: 100px;
  margin: 20px auto;
}


/** Menu restaurant **/
.product-disabled,
.product-disabled .MuiSvgIcon-root,
.category-disabled,
.category-disabled .MuiSvgIcon-root {
  color: #bbb;
}

.title-category {
  background-color: #89009d;
  color: #eee;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.title-category.category-disabled,
.category-disabled .MuiButton-containedPrimary {
  background-color: #ccc;
}
/** /Menu restaurant **/


/** Initial steeper **/
.container {
  max-width: 700px;
  flex-grow: 1;
  margin: 10px auto;
}
.container-form {
  width: 500px
}
.content {
  padding: 10px;
}

/** Containers **/
.container-center, .MuiListItem-root.container-center {
  display: flex;
  justify-content: center;
}
.container-space-around {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.container-space-between, .MuiListItem-root.container-space-between {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}
.container-space-between h3 {
  margin: 0;
}
.container-right {
  display: flex;
  flex-direction: row-reverse;
}

/** FileUpload **/
div.reactEasyCrop_Container {
  top: 70px;
  height: 200px
}
.image-list-item {
  width: 110px;
  height: 110px;
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  float: left;
  margin: 5px;
  text-align: center;
}
.image-list-item img {
  max-width: 100px;
  max-height: 100px;
}
.image-list-item .btn-remove-image {
  border: 1px solid;
  height: 10px;
  width: 10px;
  margin-top: -30px;
  margin-left: 95px;
  background: #fff;
  color: #f00;
}
/** /FileUpload **/

/** Menú **/
.container-category {
  border: 1px solid;
  padding: 10px;
  width: 100%;
}

/** Utilities **/
.no-margin {
  margin: 0;
}


/** Dialogs **/
.backend-dialog .MuiDialogTitle-root, .backend-dialog .MuiDialogContent-root {
  padding: 10px;
  text-align: center;
}
/** /Dialogs **/

/** Forms **/
.input-correct label, .input-correct .MuiInputBase-input {
  color: #0a0;
}
.input-correct .MuiInput-underline:before{
  border-color: #0a0;
}
.icon-input-correct {
  color: #0a0;
  margin-top: 20px;
  margin-right: 5px;
}
/** /Forms **/


/** Print version **/
.containerPrintVerson {
  padding: 50px;
  text-align: center;
}
.printed-logo-alacuenta {
  margin-top: 350px;
  margin-left: 500px;
}
/** /Print version **/

/** Shopping Car **/
  /** Shopping car element **/
  .product-element {
    background-color: #eee;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    margin-bottom: 1px;
    width: 100%;
  }
  .product-element > .content-left {
    display: flex;
  }
  .product-element > .content-left img {
    border-radius: 10px 0 0 10px;
    height: 110px;
  }
  .product-element > .content-left div {
    margin: 0 10px;
    padding-top: 10px;
  }
  .product-element > .content-left h5 {
    margin: 0;
  }
  .product-element > .content-right {
    display: flex;
    flex-direction: column;
  }
  .product-element > .content-right .delete-product {
    float: right;
  }
    /** /Shopping car element **/
  /** /Shopping Car **/


/** Responsive design version **/
@media (max-width: 500px) {
  h1.title-app {
    font-size: 1.1em;
    margin: 15px;
  }
  li.MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
  li.truncated-text {
    width: 90px;
  }
  .logo-alacuenta {
    padding-top: 5px;
  }
  div.MuiCardContent-root {
    padding: 8px;
  }
  .container-form {
    width: 320px
  }
  .title-app.subtitle {
    font-size: 0.7em;
    margin-top: 25px;
  }
}

@media (max-width: 360px) {
  h1.title-app {
    font-size: 0.8em;
    margin: 18px;
  }
  
  .product-element {
    margin: 0 auto 10px;
    width: 300px;
  }
}
